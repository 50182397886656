import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		$('.hamburger').toggleClass('is-active');
		$('.nav-menu-container').toggleClass('is-active');
		$('.nav-main').toggleClass('menu-active');
		$('.nav-bg').toggleClass('is-active');		
		e.preventDefault();
	});

	// load more
  // $('#loadmore').on('click', function(e){
  //   e.preventDefault();
  //   $('.role-item:hidden').slice(0, 2).slideDown();
  //   if($('.role-item:hidden').length == 0) {
  //     $('#loadmore').addClass('hide');
  //   }
  // });

	// policy modal open
	$('.policy-modal-link').on('click', function() {
		$('#policy-modal').foundation('open');		     
		$('.btn-accept').attr("href", $(this).attr('data-apply-link'));
		$('.policy-modal-title').text($(this).attr('data-apply-title'));
	});	

	// Close policy modal on apply
	$('.btn-accept').on('click', function() {
		$('#policy-modal').foundation('close');		     
	});	
	
});
